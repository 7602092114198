import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import ReactPlayer from 'react-player';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`AMAs, Audio Experiments, and The Sunday Mailbag!`}</h1>
    <p>{`Yo,`}</p>
    <p>{`I hope you're having a helluva Sunday...a few things for ya (video, audio, and
writing) today:`}</p>
    <p><strong parentName="p">{`Friday's AMA Recording:`}</strong></p>
    <p>{`We did a 70-minute AMA on Friday, you
`}<a parentName="p" {...{
        "href": "https://vimeo.com/457581360/5c5475419b"
      }}><strong parentName="a">{`can watch it here`}</strong></a>{`. We'll start putting
these on a regular schedule - look out for more details later this week!`}</p>
    <ReactPlayer url="https://vimeo.com/457581360/5c5475419b" mdxType="ReactPlayer" />
    <br />
    <p><strong parentName="p">{`Daily Meeting Recordings`}</strong></p>
    <p><strong parentName="p">{`We launched a new experiment on Friday, where we're going to be sharing the
audio of our daily meetings.`}</strong></p>
    <p>{`If you're interested in getting them via text,
`}<del parentName="p">{`sign up here`}</del>{`. We'll also be
adding them to emails, too! We're still working through the kinks for our
international members, so expect your texts later this week if you're outside of
the US...`}</p>
    <p>{`Here's
`}<a parentName="p" {...{
        "href": "https://soundcloud.com/shaan-puri-429311607/91120-audio/s-QPVmNkqwAmQ"
      }}><strong parentName="a">{`Friday's Audio`}</strong></a>{`
&
`}<a parentName="p" {...{
        "href": "https://soundcloud.com/shaan-puri-429311607/all-access-pass-91320-week-planning/s-b26Hh2NWEQ4"
      }}><strong parentName="a">{`Sunday's Weekly Planning Session`}</strong></a></p>
    <p><strong parentName="p">{`Sunday Mailbag`}</strong></p>
    <p>{`We asked you what you wanted to know about the inner-workings of everything and
anything &....we got your back.`}</p>
    <hr></hr>
    <h2><strong parentName="h2">{`All About Shaan:`}</strong></h2>
    <p><strong parentName="p">{`How do you manage your personal life with your day job, this project AND
building a business?`}</strong></p>
    <p>{`It is hard. That's why I only committed to a few months of the all access pass.
I'm working nights and weekends and kind of being a crappy dad/husband right
now. This is fun, but not sustainable.`}</p>
    <p>{`In general - the key is having great people. You guys don't see the people
behind the scenes. Eg. Abreu and Jonathan helping make the podcast effortless.
All I have to do is sit down for 2 hours a week and push record. Ben is my right
hand man with the all access pass, and he does a ton of the heavy lifting. Etc..`}</p>
    <p>{`Also I try to prioritize ruthlessly. I want to find the 20% of work that will
deliver 80% of the return..and then I'm totally fine leaving the final 20% of
potential value on the table.`}</p>
    <p><strong parentName="p">{`What has your personal growth been since you started the podcast- for
instance, when the podcast started, I would consider Sam the 'alpha', but now,
Shaan often carries the conversation and tone and whatnot. So I am curious what
this last year is like in terms of personal inner growth- character wise.`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`I've been trying to grow in 4 areas:`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Become a great teacher`}</strong>{` - I think this is my gift, and I want to use it`}</li>
      <li parentName="ul"><strong parentName="li">{`Become a great giver`}</strong>{` - I used to be pretty selfish, I'd like to become a
great giver like my friend Ramon.`}</li>
      <li parentName="ul"><strong parentName="li">{`Become a master of meaning`}</strong>{` - this is a code phrase for a big idea that
would take a while to explain. But in short - a lot of shit happens in life,
and the key is to give it the right label/meaning. Someone said something rude
to me. Do I get pissed? Or do I laugh? It depends on the meaning I gave their
words.`}</li>
      <li parentName="ul"><strong parentName="li">{`Become habitually fit`}</strong>{` - I am rebuilding my habits (eating / exercise) to
be fit. Training 6 days a week with a coach, and improving my diet.`}</li>
    </ul>
    <p><strong parentName="p">{`Your morning routine is pretty solid and I love the One Big Thing a day. What
other routines or life hacks do you find helpful? Also, you have a very positive
mindset. Who (apart from Tony Robbins) has helped you get to where you are now
mindset wise? Any recommendations?`}</strong></p>
    <p>{`Mostly tony robbins, a bit from my current personal trainer. A bit from Naval
Ravikant. The rest was just myself/self-experimentation.`}</p>
    <p>{`The other routine is at night - my wife and I play a game called "3 things" when
we get into bed. We each tell each other the 3 moments of the day we are
grateful for. It's a great way to practice being a grateful person, and gives me
and my wife a chance to talk about something that's not baby or business
related.`}</p>
    <p><strong parentName="p">{`Working in a senior position at a large tech company (and having a young
family), how do you protect / manage time so you can work on your side hustle?`}</strong></p>
    <p>{`I cancelled a ton of meetings off my calendar that I felt were unnecessary. Some
I converted into slack chats rather than calls. In general, I found roles in my
company that fit what I want out of work. I want to work on interesting projects
with interesting people and minimize headache. So I worked with our CEO to find
that role. Also, I think I kicked ass with my previous project, which gives me
some trust/rope to work in my own style - they trust that I will get good
results.`}</p>
    <p><strong parentName="p">{`Working with a large tech company, do you have any contractual risk working on
a side project? Most contracts forbid employees a) from being a director of
another for profit company b) claim all IP during your time at the company. I'm
aware some colleagues know about your podcast. Does your company typically
encourage side creative pursuits or are there issues with other employees
knowing. I ask as I'm trying to work out whether it is good to work in quiet
(due to contract risk) or get other employees to become fans of your side
projects and pros / cons.`}</strong></p>
    <p>{`I try to keep the two worlds totally separate. Eg. separate hours, separate
laptops, etc.. but I think there's always some risks. Some companies are really
great about encouraging employees to be creative/innovative in their free time.
I don't promote this stuff at work or to coworkers (although a few are on the
list). In general - read your contract, and decide accordingly. I don't want to
prescribe any advice here.`}</p>
    <p><strong parentName="p">{`From listening to the podcast, it sounds like education is your biggest
passion. Are you more interested in starting a business or investing in
companies in this space (both?) and in which macro trends are you trying to
influence the industry?`}</strong></p>
    <p>{`Yes - I think about it like this. Sure, I could build a successful business -
but the podcast has inspired hundreds of people to build successful businesses.
Education is the highest leverage thing one individual can do, because it
unlocks potential in all your 'students'.`}</p>
    <p>{`So either I will focus on being a teacher myself, or I'll build a platform for
others to teach, or I'll keep investing in companies that do this (eg. Lambda
School). This is my jam.`}</p>
    <p><strong parentName="p">{`First of all congratulations on achieving the goal so early in the plan.
Considering the public and open approach to fundraising worked wonders, do you
think you will incorporate this into all your future ventures? What are the
potential drawbacks of this strategy? Do you think it will be sustainable if it
is used by more people?`}</strong></p>
    <p>{`I think others should "work in public" more. It's a great way to bring things TO
you, rather than you having to hunt for things. I also think now was the time do
to this, this way. Being early to a platform matters, that's why I picked doing
the rolling fund first of my 3 projects, because they are brand new and I knew I
could blitz the market.`}</p>
    <p><strong parentName="p">{`What is your information diet?`}</strong></p>
    <ul>
      <li parentName="ul">{`A lot of twitter. (I have a list called
"`}<a parentName="li" {...{
          "href": "https://twitter.com/i/lists/202927880?s=20"
        }}><strong parentName="a">{`Signal not Noise`}</strong></a>{`" that you can
follow)`}</li>
      <li parentName="ul">{`Podcasts (bill simmons for fun, Naval & Jason Calacanis for business)`}</li>
      <li parentName="ul">{`Mastermind groups with founder friends`}</li>
      <li parentName="ul">{`Books (currently reading The Selfish Gene, but no time to read due to all
access pass)`}</li>
      <li parentName="ul">{`Random YouTube videos`}</li>
    </ul>
    <p>{`Right now I'm of the mindset that I should be "creating" more than I'm
"consuming". Most people consume 100x more than they create.`}</p>
    <p><strong parentName="p">{`Can you share that Mastermind group notes/blog post?`}</strong></p>
    <p>{`Put your email in `}<a parentName="p" {...{
        "href": "https://thecareerhacks.com/"
      }}><strong parentName="a">{`https://thecareerhacks.com`}</strong></a>{` and
it will be emailed to you`}</p>
    <hr></hr>
    <h2><strong parentName="h2">{`All About The Fund:`}</strong></h2>
    <p><strong parentName="p">{`Is there seasonality to angel investing? E.g maybe Q4 tends to be dead. How
does that affect the quarter-by-quarter cadence of a rolling fund?`}</strong></p>
    <p>{`There's a small amount of seasonality (entrepreneurs work year round, but
investors disappear in december & part of summer…), but venture backed startups
are like the olympics of business. You work year round.`}</p>
    <p><strong parentName="p">{`Is there any downside to raising too much?`}</strong></p>
    <p>{`For the fund, yes. The more you raise, the more you need to deploy. Which either
means finding more high quality companies (hard), or getting bigger checks into
companies (this can be hard, if the round is hot and lots of investors want in,
you may not get to put in $250k..the founder may only give you $50k of
allocation).`}</p>
    <p><strong parentName="p">{`What happens when the fund reaches its seating limit. Will there be some kind
of culling?`}</strong></p>
    <p>{`Nah there will be some natural churn in the rolling fund every year as people
decide not to continue investing. As people churn out, I'll open up spots for
new people.`}</p>
    <hr></hr>
    <h2><strong parentName="h2">{`All About Ecom:`}</strong></h2>
    <p><strong parentName="p">{`Will you be going through launching the ecom business from scratch?`}</strong></p>
    <p>{`Yes - I sent out an email this week where we kicked off the brainstorming
process. Step one of course is to pick a product. We will brainstorm, then
narrow it down, then get quotes/samples made...and then eventually bulk order
and launch!`}</p>
    <p><strong parentName="p">{`Sounds like you're going to launch your product via dropshipping. Is this
still a valid DTC method of selling products online or if we have more time,
should we white label and create a brand? If so, is there a different launch
strategy for both?`}</strong></p>
    <p>{`I'm not planning to dropship. I'm planning to do a custom or private labelled
product. Drop shipping stresses me out. So much competition, too little
defensibility…`}</p>
    <p><strong parentName="p">{`Are there any places or masterminds you recommend around ecom?`}</strong></p>
    <p>{`I don't have any spots available - but I've heard of ecommerce fuel as a paid
community for ecom.`}</p>
    <hr></hr>
    <h2><strong parentName="h2">{`All About The Course:`}</strong></h2>
    <p><strong parentName="p">{`How will you test or validate the course idea?`}</strong></p>
    <p>{`We shall see! Personally I don't test to validate full ideas. I don't think you
can really do that. I think you can test certain aspects of your idea (eg. "what
messaging is more effective, this or that?, or "what audience segment responds
best, this or that?").`}</p>
    <p>{`If I have personal conviction that something can work, that's enough for me.
That conviction is informed by personal experiences or other datapoints (eg. if
i see someone making a ton of $$ doing something, then I know there's demand
there)`}</p>
    <p><strong parentName="p">{`What platform will you use to host the course and how will you get initial
traffic?`}</strong></p>
    <p>{`I'll outline this when I start the course product!`}</p>
    <hr></hr>
    <h2><strong parentName="h2">{`On Buying A Business:`}</strong></h2>
    <p><strong parentName="p">{`Something that struck me recently was the idea of buying a business instead of
starting one. Do you have any recommendations on where to buy an existing
business? I just want to be pointed in the right direction, I started on sites
like flippa but no idea how to rate the businesses for quality (some seem
scammy).`}</strong></p>
    <p>{`First - I think buying is better than building from scratch (in fact, this may
be what I do my course on).`}</p>
    <p>{`Second - I would start by looking at curated marketplaces like
quietlightbrokerage.com rather than sites like flippa that have all kinds of
junk.`}</p>
    <p><strong parentName="p">{`I'm looking to replicate the Andrew Wilkinson Tiny model but with smaller tech
companies. Can you share some tactical advice on what you would do tomorrow to
execute on this? Particularly how you would craft your pitch to a solo tech
founder to sell a majority stake in their company? Any advice on a strategy to
get companies to come to you that want to sell etc.?`}</strong></p>
    <p>{`You could start with companies that are "failing to live up to expectations".
For example, my friends bought UpCounsel - a great service, that had real
revenues, but had raised too much venture capital and wasn't going to become a
billion dollar co. They got a great deal because that business needed a restart
with a different management mindset.`}</p>
    <p>{`Alternatively, you can go to any app you really like and say: "hey `}{`_`}{`_`}{`_`}{`_`}{` -
I'm a big fan of `}{`[app name]`}{` - it's such a `}{`[positive reason you like the app]`}{`.`}</p>
    <p>{`I'm reaching out because I'm looking to buy businesses that are `}{`[some criteria]`}{`,
and `}{`[app name]`}{` fits this model. I don't know if you've ever thought about
selling the business, but I'd love to chat with you and make you an offer. If
you don't like it, you can always say no, but a chat never hurt :)`}</p>
    <p>{`Blah blah schedule a time.`}</p>
    <hr></hr>
    <h2><strong parentName="h2">{`Approaches to Specific Problems`}</strong></h2>
    <p><strong parentName="p">{`How do you think about sales for tech products that are raw/incomplete/early?
I want to get early feedback from users before we spend a ton of time polishing.
It's totally functional and useful, but not sexy (yet).`}</strong></p>
    <p>{`Yea - start selling sooner rather than later. If you find out that you truly
can't close the sale because it's not finished or whatever - that will tell you
where to focus your energy.`}</p>
    <p><strong parentName="p">{`Thoughts about launching a second business while already having an active
business? e.g. how to properly get a management team in place for the 1st one.`}</strong></p>
    <p>{`Is there anyone who you can promote from within? If not, then take time and find
the right person to bring in first - before building the new thing. That's the
responsible thing to do, but you're welcome to be irresponsible if you want :)`}</p>
    <p><strong parentName="p">{`How do you think about differentiating in 'commodity' saas markets? Basically
where everyone has feature parity (eg email schedulers like mixmax, video tools,
etc.). Seems like distribution is king here and less so product?`}</strong></p>
    <p>{`I just think these are not "winner take all" markets typically, because there's
no network effects. Luckily the market for these things is so large, you can own
5% of the market and have a $10M+ business.`}</p>
    <p><strong parentName="p">{`When initially launching a saas business with limited time/capital, should
founder attention focus on growing/retaining the user base with existing capital
or on approaching investors to acquire funds to facilitate higher spend on
gaining users? Or is a mix of the two important?`}</strong></p>
    <p>{`First, nail it, then scale it. "Nail it" means get it to an initial cluster of
users, and see if they are happy, using it, and sticking with it. If you have
that, then switch gears to scaling up (new users), which might require
fundraising.`}</p>
    <p><strong parentName="p">{`What are good growth strategies for marketing a marketplace?`}</strong></p>
    <p>{`Checkout this
`}<a parentName="p" {...{
        "href": "https://www.lennyrachitsky.com/p/how-the-biggest-consumer-apps-got"
      }}><strong parentName="a">{`post by Lenny about how popular marketplaces got their first 1000 customers`}</strong></a></p>
    <p><strong parentName="p">{`When do you think a niche is too competitive? Eg I’m looking at Shopify apps
and boy does it seem like a red ocean race to the bottom. But I’m sure there are
still riches but it’s just more competitive. When is too much?`}</strong></p>
    <p>{`Questions like this are impossible to answer, it's all judgement calls. For
shopify apps in particular, I don't think it's red ocean. That space is growing,
and the current apps are very unsophisticated.`}</p>
    <p><strong parentName="p">{`Any recommendations for how to connect with folks and persuade them to chat
about their industry so I can narrow in on opportunities? assuming I exhausted
my warm contacts.`}</strong></p>
    <p>{`This should be easy. If this is hard for you, you're probably overcomplicating
it. Just find people, reach out with a short email asking for help, at least 20%
will oblige.`}</p>
    <p><strong parentName="p">{`For context, I am at PhD graduate in Wireless Communications currently working
with Semiconductor/MicroChip design Multinational corporation in India and have
about 8 years of experience in this industry. My work has been primarily working
on a very small module of chip design, often working alone or in a very small
group. Even if I work for years in my company, it will be very difficult to
start my own company later since Semiconductor/MicroChip design is highly
capital intensive and low return business. Startups in this domain were very
popular in 80s and 90s, however no longer. I don't have any business ideas and
finding it hard to come up with one, just a simple, nothing big..... I don't
know where to start, what to do? I was thinking of doing courses to study
machine learning and app design. However, as far as I know, its better to have a
aim/an idea to execute and then learn skills around it. Can you point to
resources/give suggestions on what can I do to come up with idea (small is ok)
to develop and try it out to get started.`}</strong></p>
    <p>{`Listen to the my first million podcast- we spout out tons of ideas a week. Find
one that resonates, and go try to solve that problem.`}</p>
    <hr></hr>
    <h2><strong parentName="h2">{`More Feedback:`}</strong></h2>
    <p><strong parentName="p">{`I liked the birthdate candle idea in the last episode - do you think it would
work for different religions e.g Christian birth month, Muslim birth month,
Hindu birth month`}</strong></p>
    <p>{`Maybe. I think religion x popular product category == winner. Not sure about
month. But religion themed candles seems like a decent idea!`}</p>
    <p><strong parentName="p">{`Here's an idea that I have been working on. I purchased the domain,
infoathlete.com, and have a website up. For context, I'm a former NCAA athlete
and professor that plans to provide information/coaching to athletes and parents
about a myriad of topics (Getting exposure, scholarships, parent questions,
ensure success, your athlete's success, etc). I was thinking of creating a
newsletter and a subscription model, like a facebook group. I could offer more
individualized coaching for a premium too. In addition, I could provide
informational sessions where I capture email addresses for attendance. I went
viral a couple of months ago when I recommended a stadium name change at Florida
State University. How would you think about building this?`}</strong></p>
    <p>{`The part where you said "myriad of topics" seems like the problem. I'd pick one
target audience (eg. athletes) and one topic (eg. getting scholarships) and
create a pdf guide to that, and collect email addresses with that. Then once you
get 1k emails, start your newsletter.`}</p>
    <p><strong parentName="p">{`I recently joined Antler. What’s your opinion on the program and does it carry
weight in the VC community from a founders perspective?`}</strong></p>
    <p>{`I've never heard of Antler, which I think answers your question`}</p>
    <p><strong parentName="p">{`On The All Access Pass:`}</strong></p>
    <p><strong parentName="p">{`Why not do a SaaS or micro-SaaS product for the All Access Pass? Is it because
they are much more complex in nature compared to info products or e-commerce
businesses and don't fit into the timeline you envisioned for the All Access
Pass? In general, do you recommend `}{`*`}{`avoiding`}{`*`}{` SaaS products if it is your
first business?`}</strong></p>
    <p>{`I would have, but my audience skews non-technical, so I went with 3 things you
can build with zero coding. I love SaaS (and software in general), so maybe will
do something like that in the future, if the all access pass continues`}</p>
    <p>{`Until tomorrow,`}<br parentName="p"></br>{`
`}{`shaan`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      